import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai'; // Importing the left arrow icon
import logo from '../assets/images/logo.jpg';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const isMainPage = location.pathname === '/' || location.pathname === '/saved-plan';

  return (
    <header style={styles.header}>
      {!isMainPage && ( 
        <div style={styles.backButton} onClick={handleBack}>
          <AiOutlineLeft style={styles.arrow} />
          <span>Back</span>
        </div>
      )}
      <img src={logo} alt="Logo" style={styles.logo} />
    </header>
  );
};

const styles = {
  header: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px',
  },
  backButton: {
    position: 'absolute',
    left: '20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '16px', // Font size for the text
    color: '#000', // Text color
  },
  arrow: {
    marginRight: '5px', // Space between the arrow and the "Back" text
    fontSize: '18px', // Arrow size
  },
  logo: {
    height: '40px',
  },
};

export default Header;
