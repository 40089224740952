import React, { useState } from 'react';
import { Button, Grid, Typography, Box, Paper } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressIndicator from './ProgressIndicator';
import NextButton from './NextButton';
import '../styles/MainChoice.css';

const MainChoice = () => {
  const navigate = useNavigate();
  const [choice, setChoice] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const country = queryParams.get('country');
  const handleNext = () => {
    navigate(`/island-selection?country=${country}&land=${choice}`);
  };
  return (
    <div className="selection-screen">
      <div className="selection-content">
        {/* <div className="selection-section"> */}
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={6} md={3} >
            <div className="choice-section" style={{paddingTop:'50px'}}>
              <button
                className={`choice-button ${
                  choice === 'island' ? 'selected' : ''
                }`}
                onClick={() => setChoice('island')}
              >
                ISLAND ESCAPE
              </button>
              <Typography
                variant="h6"
                className="or-text"
                style={{ fontWeight: 'bold', margin: '0 10px' }}
              >
                OR
              </Typography>
              <button
                className={`choice-button ${
                  choice === 'mainland' ? 'selected' : ''
                }`}
                onClick={() => setChoice('mainland')}
              >
                MAINLAND EXPLORATION
              </button>
            </div>
          </Grid>
        </Grid>
        {/* <div className="main-choice-screen">
          <div className="choice-section">
            <button
              className={`choice-button ${
                choice === 'island' ? 'selected' : ''
              }`}
              onClick={() => setChoice('island')}
            >
              ISLAND ESCAPE
            </button>
            <Typography
              variant="h6"
              className="or-text"
              style={{ fontWeight: 'bold', margin: '0 10px' }}
            >
              OR
            </Typography>
            <button
              className={`choice-button ${
                choice === 'mainland' ? 'selected' : ''
              }`}
              onClick={() => setChoice('mainland')}
            >
              MAINLAND EXPLORATION
            </button>
          </div>
        </div> */}
        {/* </div> */}
        <div className="progress-indicator-section">
          <Typography
            variant="h5"
            align="center"
            style={{ marginBottom: '20px', fontWeight: 'bold' }}
          >
            Visualize your holiday Step by Step
          </Typography>

          <ProgressIndicator steps={8} currentStep={3} />
          <div style={{ marginTop: '20px' }}>
            <NextButton onClick={handleNext} disabled={!choice} />
          </div>
          <div>
            {choice ? (
              <p></p>
            ) : (
              <p style={{ color: 'red' }}>Please select to proceed</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainChoice;
