import React, { useState, useEffect } from 'react';

function LoadingScreen({ text, time }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 95) {
          clearInterval(timer);
          return 95;
        }
        return prevProgress + 1;
      });
    }, 3000 / time);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div style={styles.container}>
      <p style={styles.text}>{text}</p>
      <div style={styles.progressBarContainer}>
        <div style={{ ...styles.progressBar, width: `${progress}%` }}></div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Full height of the viewport
    backgroundColor: '#BFC6C9', // Background color
  },
  text: {
    marginBottom: 20, // Space between text and bar
    fontSize: '24px', // Increased font size
    fontWeight: 'bold', // Bold text
  },
  progressBarContainer: {
    width: '80%', // Width of the progress bar container
    backgroundColor: '#ddd', // Light grey background for the container
    borderRadius: 8, // Rounded corners
    overflow: 'hidden', // Ensures the inner bar doesn't overflow
    height: 20, // Fixed height of the bar
  },
  progressBar: {
    height: '100%', // Full height of the container
    background: 'linear-gradient(to right, #000, #444, #000)', // Gradient effect
    backgroundSize: '200% 100%', // Size of the gradient
    animation: 'shift 3s ease-in-out infinite', // Animation settings
    transition: 'width 1s ease-in-out', // Smooth transition for the bar growth
  },
};

// Additional style element for keyframes animation
document.styleSheets[0].insertRule(`
@keyframes shift {
  0% { background-position: 100% 0%; }
  100% { background-position: -100% 0%; }
}`, document.styleSheets[0].cssRules.length);

export default LoadingScreen;
