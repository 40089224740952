// src/components/ContinentSelection.js
import React, { useState } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import ContinentCard from './ContinentCard';
import { useNavigate } from 'react-router-dom';
import ProgressIndicator from './ProgressIndicator';
import NextButton from './NextButton';
import '../styles/ContinentSelection.css';

const continents = [
  { name: 'europe', label: 'Europe' },
  { name: 'northAmerica', label: 'North America' },
  { name: 'oceania', label: 'Oceania' },
  { name: 'asia', label: 'Asia' },
  { name: 'southAmerica', label: 'South America' },
  { name: 'africa', label: 'Africa' },
];

const ContinentSelection = () => {
  const navigate = useNavigate();
  const [selectedContinent, setSelectedContinent] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelection = (name) => {
    setSelectedContinent(name);
  };
  const handleNext = () => {
    navigate(`/country-selection?continent=${selectedContinent}`);
  };
  return (
    <div className="selection-screen">
      <div className="selection-content">
        <div className="selection-section">
          <div
            className="selection-screen"
            style={{ textAlign: 'Left', padding: '20px' }}
          >
            <Typography
              variant="h5"
              gutterBottom
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              pt={isMobile ? 6 : 0}
            >
              Choose One of the Continents
            </Typography>
            <Grid
              container
              spacing={2}
              style={{
                overflowX: isMobile ? 'auto' : 'hidden',
                flexWrap: isMobile ? 'nowrap' : 'wrap',
              }}
            >
              {continents.map((continent) => (
                <Grid
                  item
                  key={continent.name}
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ flex: isMobile ? '0 0 auto' : '1 0 auto' }}
                >
                  <ContinentCard
                    name={continent.label}
                    selected={selectedContinent === continent.name}
                    onClick={() => handleSelection(continent.name)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div className="progress-indicator-section">
          <Typography
            variant="h5"
            align="center"
            style={{ marginBottom: '20px', fontWeight: 'bold' }}
          >
            Visualize your holiday Step by Step
          </Typography>

          <ProgressIndicator steps={8} currentStep={1} />
          <div style={{ marginTop: '20px' }}>
            <NextButton onClick={handleNext} disabled={!selectedContinent} />
          </div>
          <div>
            {selectedContinent ? (
              <p></p>
            ) : (
              <p style={{ color: 'red' }}>Please select to proceed</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinentSelection;
