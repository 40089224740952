// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import MainButton from './components/MainButton';
import ContinentSelection from './components/ContinentSelection';
import IslandSelection from './components/CitySelection'; // Import the renamed IslandSelection component
import MainChoice from './components/MainChoice';
import GetawaySelection from './components/GetawaySelection';
import DestinationSelection from './components/DestinationSelection';
import TransitSelection from './components/TransitSelection';
import TravelStyleSelection from './components/TravelStyleSelection';
import CountrySelection from './components/CountrySelection';
import SavedPlan from './components/savedPlaned';
import { QueryClient, QueryClientProvider } from 'react-query';
import TripSummary from './components/TripSummary';
import { NextUIProvider } from '@nextui-org/react';
function App() {
  const queryClient = new QueryClient();

  return (
    <NextUIProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <div className="App">
            <Header />
            {/* <div className="main"> */}
            <Routes>
              <Route path="/" element={<MainButton />} />
              <Route
                path="/continent-selection"
                element={
                  <div className="main">
                    <ContinentSelection />
                  </div>
                }
              />
              <Route
                path="/country-selection"
                element={
                  <div className="main">
                    <CountrySelection />
                  </div>
                }
              />

              <Route
                path="/main-choice"
                element={
                  <div className="main">
                    <MainChoice />
                  </div>
                }
              />
              <Route
                path="/island-selection"
                element={
                  <div className="main">
                    <IslandSelection />
                  </div>
                }
              />
              <Route
                path="/getaway-selection"
                element={
                  <div className="main">
                    <GetawaySelection />
                  </div>
                }
              />
              <Route
                path="/destination-selection"
                element={
                  <div className="main">
                    <DestinationSelection />
                  </div>
                }
              />
              <Route
                path="/trasnit-selection"
                element={
                  <div className="main">
                    <TransitSelection />
                  </div>
                }
              />
              <Route
                path="/travelstyle-selection"
                element={
                  <div className="main">
                    <TravelStyleSelection />
                  </div>
                }
              />
              <Route
                path="/trip-planner"
                element={
                  // <div className="main">
                  <TripSummary />
                  // </div>
                }
              />
              <Route
                path="/saved-plan"
                element={
                  // <div className="main">
                  <SavedPlan />
                  // </div>
                }
              />
            </Routes>
            {/* </div> */}
          </div>
        </Router>
      </QueryClientProvider>
    </NextUIProvider>
  );
}

export default App;
