import React from 'react';

function RegenerateScreen({ refetch }) {
  return (
    <div style={styles.container}>
      <p style={styles.text}>Please Regernerate</p>
      <div>
        <button
          onClick={() => refetch()}
          style={{
            backgroundColor: 'black',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
          }}
        >
          Re-generate
        </button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Full height of the viewport
    backgroundColor: '#BFC6C9', // Background color
  },
  text: {
    marginBottom: 20, // Space between text and bar
    fontSize: '24px', // Increased font size
    fontWeight: 'bold', // Bold text
  },
};

export default RegenerateScreen;
