// src/components/ProgressIndicator.js
import React from 'react';
import { Box } from '@mui/material';

const ProgressIndicator = ({ steps, currentStep }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" margin="20px 0">
      {Array.from({ length: steps }).map((_, index) => (
        <Box
          key={index}
          width={20}
          height={20}
          margin="0 5px"
          borderRadius="50%"
          bgcolor={index < currentStep ? '#FFA500' : '#ddd'}
        />
      ))}
    </Box>
  );
};

export default ProgressIndicator;
