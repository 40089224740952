import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
} from '@mui/material';
import '../styles/TransitCard.css'; // Additional styles for the TransitCard

const TransitCard = ({ name, icon, selected, onClick }) => {
  return (
    <Card
      onClick={onClick}
      className={`transit-card ${selected ? 'selected' : ''}`} // Corrected missing closing brace
      style={{
        // border: selected ? '3px solid #FFA500' : '1px solid transparent',
        borderRadius: '10px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)', // Subtle shadow for depth
        width: '180px', // Sufficient width to display the continent image
        height: '200px', // Adjust height based on your content
        margin: '10px', // Space between cards
        backgroundColor: '#fff', // Background color white for non-selected
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Adjusted to space-between to align content
        alignItems: 'center',
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          alt={name}
          height="150"
          image={icon}
          title={name}
        />
        <CardContent
          style={{
            backgroundColor: selected ? '#FFA500' : '#000',
            color: '#fff',
            width: '100%',
            padding: '10px 0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', // Ensures vertical alignment
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h6"
            align="center"
            style={{
              marginTop: '1px',
              lineHeight: '1.5',
            }}
          >
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TransitCard;
