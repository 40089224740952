import React, { useState } from 'react';
import {
  Typography,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Grid,
} from '@mui/material';
import '../styles/DestinationSelection.css';
import ProgressIndicator from './ProgressIndicator';
import NextButton from './NextButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useMediaQuery, useTheme } from '@mui/material';

const DestinationSelection = () => {
  const [startLocation, setStartLocation] = useState('');
  const [endLocation, setEndLocation] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const country = queryParams.get('country');
  const land = queryParams.get('land');
  const getaway = queryParams.get('getaway');
  const city = queryParams.get('city');
  const locations = decodeURIComponent(city)?.split(',  ');
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleNext = () => {
    navigate(
      `/trasnit-selection?country=${country}&land=${land}&city=${city}&getaway=${getaway}&startLocation=${startLocation}&endLocation=${endLocation}&startDate=${startDate.format(
        'DD-MMM-YYYY'
      )}&endDate=${endDate.format('DD-MMM-YYYY')}`
    );
  };
  return (
    <div className="selection-screen">
      <div className="selection-content">
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={9}>
            <Typography
              variant="h6"
              // className="selection-title"
              style={{
                fontWeight: 'bold',
                textAlign: isMobile ? 'center' : 'left',
              }}
              pt={isMobile ? 12 : 2}
              m={1}
            >
              Choose
              {locations[0] !== 'anywhere' && 'Start / End Destination /'}{' '}
              Travel dates
            </Typography>
            {locations[0] !== 'anywhere' && (
              <>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>Start location</InputLabel>
                  <Select
                    value={startLocation}
                    onChange={(e) => setStartLocation(e.target.value)}
                    label="Start location"
                    style={{
                      backgroundColor: '#fff',
                      alignContent: 'left',
                      maxWidth: '600px',
                    }}
                  >
                    {locations?.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location.split(' - ')[0]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel>End location</InputLabel>
                  <Select
                    value={endLocation}
                    onChange={(e) => setEndLocation(e.target.value)}
                    label="End location"
                    style={{ backgroundColor: '#fff', maxWidth: '600px' }}
                  >
                    {locations?.map((location) => (
                      <MenuItem key={location} value={location}>
                        {location.split(' - ')[0]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                sx={{
                  backgroundColor: '#fff',
                  maxWidth: '600px',
                  borderRadius: '6px',
                }}
                slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                minDate={dayjs()}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                sx={{
                  backgroundColor: '#fff',
                  maxWidth: '600px',
                  borderRadius: '6px',
                }}
                slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                minDate={dayjs(startDate)}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className="progress-indicator-section">
              <Typography
                variant="h5"
                align="center"
                style={{ marginBottom: '20px', fontWeight: 'bold' }}
              >
                Visualize your holiday Step by Step
              </Typography>

              <ProgressIndicator steps={8} currentStep={6} />
              <div style={{ marginTop: '20px' }}>
                <NextButton
                  onClick={handleNext}
                  disabled={
                    !startDate && !endDate && !startLocation && !endLocation
                  }
                />
              </div>
              <div>
                {startDate && endDate && startLocation && endLocation ? (
                  <p></p>
                ) : (
                  <p style={{ color: 'red' }}>Please select to proceed</p>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DestinationSelection;
