import React from 'react';
import '../styles/NextButton.css'; // Ensure the correct CSS file is referenced

const NextButton = ({ onClick, disabled }) => {
  return (
    <button onClick={onClick} className="next-button" disabled={disabled}>
      NEXT
    </button>
  );
};

export default NextButton;
