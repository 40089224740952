import React, { useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import '../styles/CountrySelection.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressIndicator from './ProgressIndicator';
import NextButton from './NextButton';
import ClearIcon from '@mui/icons-material/Clear';

const continentCountries = {
  europe: {
    'Western Europe': [
      'Austria',
      'Belgium',
      'France',
      'Germany',
      'Ireland',
      'Liechtenstein',
      'Luxembourg',
      'Monaco',
      'Netherlands',
      'Switzerland',
      'United Kingdom',
    ],
    'Eastern Europe': [
      'Belarus',
      'Bulgaria',
      'Czech Republic',
      'Hungary',
      'Moldova',
      'Poland',
      'Romania',
      'Russia',
      'Slovakia',
      'Ukraine',
    ],
    'Southern Europe': [
      'Albania',
      'Andorra',
      'Bosnia and Herzegovina',
      'Croatia',
      'Greece',
      'Italy',
      'Kosovo',
      'Montenegro',
      'North Macedonia',
      'Portugal',
      'San Marino',
      'Serbia',
      'Slovenia',
      'Spain',
      'Vatican City',
    ],
    'Northern Europe': [
      'Denmark',
      'Estonia',
      'Finland',
      'Iceland',
      'Latvia',
      'Lithuania',
      'Norway',
      'Sweden',
    ],
  },
  asia: {
    'Northern Asia': [
      'Russia', // Part of Russia that is in Asia
    ],
    'Western Asia': [
      'Armenia',
      'Azerbaijan',
      'Bahrain',
      'Cyprus',
      'Georgia',
      'Iraq',
      'Israel',
      'Jordan',
      'Kuwait',
      'Lebanon',
      'Oman',
      'Qatar',
      'Saudi Arabia',
      'Palestine',
      'Syria',
      'Turkey',
      'United Arab Emirates',
      'Yemen',
    ],
    'Central Asia': [
      'Kazakhstan',
      'Kyrgyzstan',
      'Tajikistan',
      'Turkmenistan',
      'Uzbekistan',
    ],
    'South Asia': [
      'Afghanistan',
      'Bangladesh',
      'Bhutan',
      'India',
      'Maldives',
      'Nepal',
      'Pakistan',
      'Sri Lanka',
    ],
    'East Asia': [
      'China',
      'Japan',
      'Mongolia',
      'North Korea',
      'South Korea',
      'Taiwan',
    ],
    'Southeast Asia': [
      'Brunei',
      'Cambodia',
      'Indonesia',
      'Laos',
      'Malaysia',
      'Myanmar',
      'Philippines',
      'Singapore',
      'Thailand',
      'Timor-Leste',
      'Vietnam',
    ],
  },
  northAmerica: {
    'Northern America': [
      'Canada',
      'United States',
      'Greenland', // Although politically part of Denmark, Greenland is geographically part of North America.
      'Bermuda', // A British Overseas Territory, often considered part of Northern America in some contexts.
      'Mexico',
    ],
    'Central America': [
      'Belize',
      'Costa Rica',
      'El Salvador',
      'Guatemala',
      'Honduras',
      'Nicaragua',
      'Panama',
    ],
    'The Caribbean': [
      'Antigua and Barbuda',
      'Bahamas',
      'Barbados',
      'Cuba',
      'Dominica',
      'Dominican Republic',
      'Grenada',
      'Haiti',
      'Jamaica',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Vincent and the Grenadines',
      'Trinidad and Tobago',
    ],
  },
  southAmerica: {
    'Andean States': ['Bolivia', 'Colombia', 'Ecuador', 'Peru', 'Venezuela'],
    'Southern Cone': ['Argentina', 'Chile', 'Paraguay', 'Uruguay'],
    Brazil: [
      // Brazil is often treated as its own region due to its size and influence.
      'Brazil',
    ],
    'The Guianas': [
      'Guyana',
      'Suriname',
      'French Guiana', // Although not a sovereign country but a French overseas department, it's geographically part of this subregion.
    ],
  },
  oceania: {
    Australasia: ['Australia', 'New Zealand'],
    Melanesia: [
      'Fiji',
      'Papua New Guinea',
      'Solomon Islands',
      'Vanuatu',
      'New Caledonia', // Although a French overseas territory, it's geographically part of Melanesia.
    ],
    Micronesia: [
      'Federated States of Micronesia',
      'Kiribati',
      'Marshall Islands',
      'Nauru',
      'Palau',
    ],
    Polynesia: [
      'Samoa',
      'Tonga',
      'Tuvalu',
      'Cook Islands', // A self-governing territory in free association with New Zealand.
      'Niue', // Another self-governing state in free association with New Zealand.
      'American Samoa', // An unincorporated territory of the United States.
      'French Polynesia', // A French overseas collectivity.
      'Tokelau', // A dependent territory of New Zealand.
      'Wallis and Futuna', // A French overseas collectivity.
      'Pitcairn Islands', // A British Overseas Territory.
    ],
  },
  africa: {
    'North Africa': [
      'Algeria',
      'Egypt',
      'Libya',
      'Morocco',
      'Sudan',
      'Tunisia',
      'Western Sahara', // Disputed territory primarily administered by Morocco.
    ],
    'West Africa': [
      'Benin',
      'Burkina Faso',
      'Cape Verde',
      'Ivory Coast',
      'Gambia',
      'Ghana',
      'Guinea',
      'Guinea-Bissau',
      'Liberia',
      'Mali',
      'Mauritania',
      'Niger',
      'Nigeria',
      'Senegal',
      'Sierra Leone',
      'Togo',
    ],
    'Central Africa': [
      'Angola',
      'Cameroon',
      'Central African Republic',
      'Chad',
      'Congo',
      'Democratic Republic of the Congo',
      'Equatorial Guinea',
      'Gabon',
      'Sao Tome and Principe',
    ],
    'East Africa': [
      'Burundi',
      'Comoros',
      'Djibouti',
      'Eritrea',
      'Ethiopia',
      'Kenya',
      'Madagascar',
      'Malawi',
      'Mauritius',
      'Mozambique',
      'Rwanda',
      'Seychelles',
      'Somalia',
      'South Sudan',
      'Tanzania',
      'Uganda',
      'Zambia',
      'Zimbabwe',
    ],
    'Southern Africa': [
      'Botswana',
      'Eswatini (formerly Swaziland)',
      'Lesotho',
      'Namibia',
      'South Africa',
    ],
  },
};

const CountrySelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const continent = queryParams.get('continent');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCountryClick = (country) => {
    setSelectedCountry(country);
  };
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const filteredCountries = Object.entries(
    continentCountries[continent]
  ).reduce((acc, [region, countryList]) => {
    acc[region] = countryList.filter((country) =>
      country.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return acc;
  }, {});
  const handleNext = () => {
    navigate(`/main-choice?country=${selectedCountry}`);
  };
  return (
    <div className="selection-screen">
      <div className="selection-content">
        {/* <div className="selection-section"> */}
        <div className="country-selection-screen">
          <Typography
            variant="h6"
            style={{ marginTop: '10px', fontWeight: 'bold' }}
          >
            Choose one of the Countries
          </Typography>
          <TextField
            label="Search a country name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ backgroundColor: 'AppWorkspace', borderRadius: '6px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear search"
                    onClick={handleClearSearch}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {Object.entries(filteredCountries).map(([region, countryList]) => (
            <div key={region} className="country-group">
              <Typography variant="h6" className="region-title">
                {countryList.length > 0 && region}
              </Typography>
              <Grid container spacing={2}>
                {countryList.map((country) => (
                  <Grid item xs={6} sm={6} md={4} key={country}>
                    <button
                      className={`country-button ${
                        selectedCountry === country ? 'selected' : ''
                      }`}
                      onClick={() => handleCountryClick(country)}
                    >
                      {country}
                    </button>
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </div>
        {/* </div> */}
        <div className="progress-indicator-section">
          <Typography
            variant="h5"
            align="center"
            style={{ marginBottom: '20px', fontWeight: 'bold' }}
          >
            Visualize your holiday Step by Step
          </Typography>

          <ProgressIndicator steps={8} currentStep={2} />
          <div style={{ marginTop: '20px' }}>
            <NextButton onClick={handleNext} disabled={!selectedCountry} />
          </div>
          <div>
            {selectedCountry ? (
              <p></p>
            ) : (
              <p style={{ color: 'red' }}>Please select to proceed</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountrySelection;
