import React, { useState } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import TransitCard from './TransitCard';
import '../styles/TransitSelection.css';
import ProgressIndicator from './ProgressIndicator';
import NextButton from './NextButton';
import { useNavigate, useLocation } from 'react-router-dom';

const transitOptions = [
  { name: 'Mixed', icon: 'https://i.ibb.co/yFrjF2Q/Screenshot-from-2024-06-15-19-48-12.png' },
  { name: 'Car Rental', icon: 'https://i.ibb.co/nBC0W5Z/Screenshot-from-2024-06-15-19-49-52.png' },
  { name: 'Public Transit', icon: 'https://i.ibb.co/KVJ1bJn/Screenshot-from-2024-06-15-19-50-01.png' },
];

const TransitSelection = () => {
  const [selectedTransit, setSelectedTransit] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const country = queryParams.get('country');
  const land = queryParams.get('land');
  const getaway = queryParams.get('getaway');
  const city = queryParams.get('city');
  const startLocation = queryParams.get('startLocation');
  const endLocation = queryParams.get('endLocation');
  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const handleSelection = (name) => {
    setSelectedTransit(name);
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    navigate(
      `/travelstyle-selection?country=${country}&land=${land}&city=${city}&getaway=${getaway}&startLocation=${startLocation}&endLocation=${endLocation}&startDate=${startDate}&endDate=${endDate}&transit=${selectedTransit}`
    );
  };
  return (
    <div className="selection-screen">
      <div className="selection-content">
        <div className="selection-section">
          <div className="transit-selection-screen">
            <div className="selection-area">
              <Typography
                variant="h5"
                gutterBottom
                style={{ fontWeight: 'bold', textAlign: 'start' }}
                pt={isMobile ? 8 : 0}

              >
                Transit
              </Typography>
              <Grid
                container
                spacing={2}
                style={{
                  overflowX: isMobile ? 'auto' : 'hidden',
                  flexWrap: isMobile ? 'nowrap' : 'wrap',
                }}
              >
                {transitOptions.map((transit, index) => (
                  <Grid
                    item
                    key={transit.name}
                    xs={12}
                    sm={6}
                    md={4}
                    style={{ flex: isMobile ? '0 0 auto' : '1 0 auto' }}
                  >
                    <TransitCard
                      name={transit.name}
                      icon={transit.icon}
                      selected={selectedTransit === transit.name}
                      onClick={() => handleSelection(transit.name)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
        <div className="progress-indicator-section">
          <Typography
            variant="h5"
            align="center"
            style={{ marginBottom: '20px', fontWeight: 'bold' }}
          >
            Visualize your holiday Step by Step
          </Typography>

          <ProgressIndicator steps={8} currentStep={7} />
          <div style={{ marginTop: '20px' }}>
            <NextButton onClick={handleNext} disabled={!selectedTransit} />
          </div>
          <div>
            {selectedTransit ? (
              <p></p>
            ) : (
              <p style={{ color: 'red' }}>Please select to proceed</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransitSelection;
