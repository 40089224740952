// components/TripSummary.js
import React, { useEffect, useState } from 'react';
import '../styles/TripSummary.css';
import { marked } from 'marked';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import MapView from './MapView';
import LoadingScreen from './loader';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import RegenerateScreen from './Regnerate';
const SavedPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('planId');
  const startDate = queryParams.get('startDate');
  const country = queryParams.get('country');
  const endDate = queryParams.get('endDate');
  const fetchTravelPlan = ({ queryKey }) => {
    const [planId] = queryKey;
    return axios
      .get(`https://ai.orienjo.co.uk/getplan?planId=${planId}`)
      .then((res) => res.data);
  };
  const { isLoading, error, data, refetch, isRefetching } = useQuery(
    [planId],
    fetchTravelPlan,
    {
      refetchOnWindowFocus: false,
    }
  );

  const renderMarkdown = (markdownText) => {
    const renderer = new marked.Renderer();
    const oldLinkRenderer = renderer.link;
    renderer.link = (href, title, text) => {
      const sanitizedHref = href.replace(/'/g, '');
      const html = oldLinkRenderer.call(renderer, sanitizedHref, title, text);
      return html.replace(
        /^<a /,
        `<a onclick="handleLinkClick(event, '${sanitizedHref}')" style="color: black; font-size: 16px; font-weight: bold;" `
      );
    };
    marked.setOptions({
      renderer,
    });

    return marked(markdownText);
  };
  const apiKey = process.env.REACT_APP_MAP_API_KEY;
  const fetchPlaceDetails = async (placeId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/placeDetails?placeId=${placeId}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  };

  const [clickLocation, setClickLocation] = useState();
  const [placeImages, setPlaceImages] = useState();
  window.handleLinkClick = (event, url) => {
    event.preventDefault();
    const urlSearch = new URL(url);
    const pathSegments = url.split('/');
    const locationName = decodeURIComponent(
      pathSegments[pathSegments.length - 1]
    );
    const hasCountry = locationName
      .toLowerCase()
      .includes(country.toLowerCase());
    const searchLocation = hasCountry
      ? locationName
      : locationName + ', ' + country;
    try {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/textsearch?query=${encodeURIComponent(searchLocation)}`
        )
        .then(async (response) => {
          const data = response.data;
          const { lat, lng } = data.results[0].geometry.location;
          const place = await fetchPlaceDetails(data.results[0].place_id);
          setPlaceImages(place);
          setClickLocation({
            lat,
            lng,
          });
          // }
        });
    } catch (error) {
      alert('Location not found.');
    }
  };
  const [isValid, setIsValid] = useState(true);
  const validateGeoPointsData = (numLocations) => {
    const geoPointsPattern = /^-?\d+\.\d+,-?\d+\.\d+$/;
    for (let i = 0; i < numLocations.length; i++) {
      const GeoPoints = numLocations[i].GeoPoints;
      if (!GeoPoints || GeoPoints.trim() === '') {
        setIsValid(false);
      }
      if (!geoPointsPattern.test(GeoPoints)) {
        setIsValid(false);
      }
    }

    setIsValid(true);
  };
  useEffect(() => {
    if (data) {
      validateGeoPointsData(data?.tripData.geopoints);
    }
  }, [data]);

  if (isLoading || isRefetching)
    return (
      <LoadingScreen text={'Please wait your plan is loading...'} time={5} />
    );
  if (error) return 'An error has occurred: ' + error.message;

  return (
    <>
      {!isValid && <RegenerateScreen refetch={refetch} />}
      {isMobile ? (
        <div
          className="trip-planner"
          style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
        >
          <div
            className="map-view"
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              height: '40vh',
              zIndex: 1,
            }}
          >
            <MapView
              data={data?.tripData?.geopoints}
              clickLocation={clickLocation}
              setClickLocation={setClickLocation}
              placeImages={placeImages}
              setPlaceImages={setPlaceImages}
            />
          </div>
          <div
            className="trip-summary"
            style={{
              marginTop: '47vh',
              height: 'calc(100vh - 40vh)',
              overflowY: 'auto',
              padding: '20px',
              backgroundColor: '#BFC6C9',
            }}
          >
            <h2 style={{ fontWeight: 'bold', marginBottom: '20px' }}>
              Your trip to {country} for{' '}
              {Math.ceil(
                (new Date(endDate) - new Date(startDate)) /
                  (1000 * 60 * 60 * 24)
              )}{' '}
              days:
            </h2>
            {data && (
              <div
                className="trip-details"
                dangerouslySetInnerHTML={{
                  __html: renderMarkdown(data?.tripData.travel_plan),
                }}
              />
            )}
            <div
              className="trip-actions"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
              }}
            >
              <button
                className="btn re-generate"
                onClick={() => (window.location.href = data?.url)}
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Re-generate
              </button>
              <button
                className="btn new-trip"
                onClick={() => navigate('/')}
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Create a new trip
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            height: `calc(100vh - ${70}px)`, // Subtract header height from the total height
            width: '100%',
            marginTop: `${70}px`, // Push the container down by the height of the header
          }}
        >
          {' '}
          <div
            style={{
              width: '48%', // Control the width of the summary section
              overflowY: 'auto', // Allow scrolling within the summary
              padding: '40px',
              backgroundColor: '#BFC6C9',
            }}
          >
            <h2 style={{ fontWeight: 'bold' }}>
              Your trip to {country} for{' '}
              {Math.ceil(
                (new Date(endDate) - new Date(startDate)) /
                  (1000 * 60 * 60 * 24)
              )}{' '}
              days:
            </h2>
            <div
              className="trip-details"
              dangerouslySetInnerHTML={{
                __html: renderMarkdown(data?.tripData.travel_plan),
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
              }}
            >
              <button
                onClick={() => (window.location.href = data?.url)}
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                }}
              >
                Re-generate
              </button>
              <button
                onClick={() => navigate('/')}
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                }}
              >
                Create a new trip
              </button>
            </div>
          </div>
          <div
            style={{
              width: '50%', // Control the width of the map section
              height: '100%',
            }}
          >
            <MapView
              data={data?.tripData.geopoints}
              clickLocation={clickLocation}
              setClickLocation={setClickLocation}
              placeImages={placeImages}
              setPlaceImages={setPlaceImages}
              refetch={refetch}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SavedPlan;
