import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
  Radio,
} from '@mui/material';
import EuropeImage from '../assets/images/Europe.png';
import NorthAmericaImg from '../assets/images/North_America.png';
import SouthAmericaImg from '../assets/images/South_America.png';
import AsiaImg from '../assets/images/asia.png';
import Austrailia from '../assets/images/Austrailia.png';
import AfricaImg from '../assets/images/Africa.png';

const continentImages = {
  Europe: EuropeImage,
  'North America': Austrailia,
  Oceania: NorthAmericaImg, // Assuming "Australia" represents Oceania
  Asia: AsiaImg,
  'South America': SouthAmericaImg,
  Africa: AfricaImg,
};

const ContinentCard = ({ name, selected, onClick }) => {
  return (
    <Card
      onClick={onClick}
      style={{
        // border: selected ? '1px solid #FFA500' : '2px solid transparent',
        borderRadius: '10px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)', // Subtle shadow for depth
        width: '180px', // Sufficient width to display the continent image
        height: '200px', // Adjust height based on your content
        margin: '10px', // Space between cards
        backgroundColor: '#fff', // Background color white for non-selected
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Adjusted to space-between to align content
        alignItems: 'center',
      }}
    >
      <CardActionArea style={{ height: '100%' }}>
        <CardMedia
          component="img"
          alt={name}
          height="150" // Adjust height to leave space for the label
          image={continentImages[name]}
          title={name}
        />
        <CardContent
          style={{
            backgroundColor: selected ? '#FFA500' : '#000',
            color: '#fff',
            width: '100%',
            padding: '10px 0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', // Ensures vertical alignment
            justifyContent: 'center',
          }}
        >
          {/* <Radio
            checked={selected}
            onChange={() => onClick(name)}
            value={name}
            name="continent-radio"
            color="default"
            style={{
              color: '#FFFFF',
              padding: 0,
              marginRight: '10px',
            }}
          /> */}
          <Typography
            variant="h6"
            align="center"
            style={{
              marginTop: '1px',
              lineHeight: '1.5',
            }}
          >
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ContinentCard;
