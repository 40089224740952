import * as React from 'react';
import { Modal, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
const ImageModal = ({ open, onClose, images }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: fullScreen ? '100%' : 700,
    bgcolor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent black background
    // border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  };

  return (
    <Modal keepMounted open={open} onClose={onClose}>
      <Box
        sx={{
          ...style,
          position: 'relative',
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '5px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white',
          }}
        >
          <CloseIcon/>
        </button>
        <Box
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden', // Hide vertical scrollbar if present
            display: 'flex',
            flexDirection: 'row',
            mt: 3.5,
            mb: 3.5,
            scrollbarWidth: 'none', // For Firefox
            msOverflowStyle: 'none', // For Internet Explorer and Edge
            '&::-webkit-scrollbar': {
              display: 'none', // For Chrome, Safari, and Opera
            },
          }}
        >
          {images?.map((imageSrc, index) => (
            <img
              key={index}
              src={imageSrc}
              alt={`Image ${index}`}
              style={{ width: '180px', height: '180px', marginRight: '4px' }}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageModal;
