import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressIndicator from './ProgressIndicator';
import NextButton from './NextButton';
import '../styles/GetawaySelection.css';
import { useMediaQuery, useTheme } from '@mui/material';

const GetawaySelection = () => {
  const options = [
    'Adventure in Nature',
    'Luxury Getaway',
    'Budget Backpacking',
    'City, Town Villages',
    'Food & Drink',
    'Wildlife Safaris',
    'Culture & History',
    'Family Friendly Vacations',
    'Leisure & Relax',
  ];
  const navigate = useNavigate();
  const [getaway, setGetaway] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const country = queryParams.get('country');
  const land = queryParams.get('land');
  const city = queryParams.get('city');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOptionClick = (option) => {
    if (getaway.includes(option)) {
      setGetaway(getaway.filter((item) => item !== option)); // Remove the option
    } else {
      setGetaway([...getaway, option]); // Add the option
    }
  };
  const handleNext = () => {
    const item = encodeURIComponent(getaway.join(','));
    navigate(
      `/destination-selection?country=${country}&land=${land}&city=${city}&getaway=${item}`
    );
  };
  return (
    <div className="selection-screen">
      <div className="selection-content">
        {/* <div className="selection-section"> */}
        <div className="getaway-selection-screen">
          <div className="selection-area">
            <Typography
              variant="h6"
              style={{
                fontWeight: 'bold',
                textAlign: isMobile ? 'center' : 'left',
              }}
              pt={6}
              m={1}
            >
              Explore the Heart of Your Getaway (Multi Optional)
            </Typography>
            <Grid container spacing={2} className="options-grid">
              {options.map((option) => (
                <Grid item xs={12} sm={6} md={4} key={option}>
                  <button
                    className={`option-button ${
                      getaway.includes(option) ? 'selected' : ''
                    }`}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </button>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        {/* </div> */}
        <div className="progress-indicator-section">
          <Typography
            variant="h5"
            align="center"
            style={{ marginBottom: '20px', fontWeight: 'bold' }}
          >
            Visualize your holiday Step by Step
          </Typography>

          <ProgressIndicator steps={8} currentStep={5} />
          <div style={{ marginTop: '20px' }}>
            <NextButton onClick={handleNext} disabled={!getaway.length} />
          </div>
          <div>
            {getaway.length ? (
              <p></p>
            ) : (
              <p style={{ color: 'red' }}>Please select to proceed</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetawaySelection;
