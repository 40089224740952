import React from 'react';
import '../styles/MainButton.css';
import { useNavigate } from 'react-router-dom';

const MainButtonScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="main-button-screen">
      <button
        className="main-button"
        onClick={() => navigate('/continent-selection')}
      >
        GENERATE YOUR TRIP
      </button>
    </div>
  );
};

export default MainButtonScreen;
